import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import Button from "@earnnest-e2-frontend/platform-ui/src/Button/Button"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import { Box, Text } from "@earnnest-e2-frontend/platform-ui/src/UI"
import React from "react"
import { useLocation } from "react-router-dom"

// Displays loading and error states after redirect from Auth0
// Auth0Provider handles the actual success callback

export default function CallbackPage() {
  const location = useLocation()
  const { error, loginWithRedirect } = useEarnnestAuth0()
  const searchParams = new URLSearchParams(location.search)
  const errorCode = searchParams.get("error")
  const errorDescription = searchParams.get("error_description")
  const returnTo = localStorage.getItem("loginReturnTo")

  return (
    <Box
      bg="contrast0"
      style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
      {errorCode || errorDescription ? (
        <>
          <Text type="heading3" color="contrast100">
            We experienced an error during login.
          </Text>
          <Box h={12} />
          <Text type="baseText" color="contrast100">
            {errorDescription ||
              error?.message ||
              "An unexpected error occurred."}
          </Text>
          <Box h={16} />
          {returnTo ? (
            <Button
              kind="primary"
              title="Try to login again"
              onPress={() => {
                loginWithRedirect({
                  appState: { returnTo },
                })
              }}
            />
          ) : null}
          <Box h={16} />
          <Text type="baseText" color="contrast100">
            If the problem persists, contact{" "}
            <Text color="green" href="mailto:support@earnnest.com">
              support@earnnest.com
            </Text>
            .
          </Text>
        </>
      ) : (
        <LoadingOverlay />
      )}
    </Box>
  )
}
