import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import { useEffect } from "react"
import { Redirect, useLocation } from "react-router-dom"

// Handles logout redirects from Auth0

export default function LogoutPage() {
  const location = useLocation()
  const { isAuthenticated, logout } = useEarnnestAuth0()
  const { track } = useEarnnestAnalytics()
  const searchParams = new URLSearchParams(location.search)
  const redirectTo = searchParams.get("redirectTo")

  useEffect(() => {
    if (isAuthenticated) {
      logout()
    } else {
      track("Logout Succeeded")
    }
  }, [track, logout, isAuthenticated])

  if (isAuthenticated) {
    return <LoadingOverlay />
  }

  if (redirectTo) {
    return <Redirect to={redirectTo} />
  }

  return <Redirect to="/" />
}
