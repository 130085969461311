import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { Flex, Space, Text, Title } from "@mantine/core"
import React, { useEffect } from "react"

interface ErrorPageProps {
  errorCode?: string | null
  errorMessage?: string | null
}

export default function ErrorPage({ errorCode, errorMessage }: ErrorPageProps) {
  const { track } = useEarnnestAnalytics()

  useEffect(() => {
    if (errorCode) {
      track(`${errorCode} Error Viewed`)
    } else {
      track("Error Viewed")
    }
  }, [track, errorCode])

  return (
    <Flex direction="column" align="center" justify="center">
      <Title size="h3" order={3}>
        Error {errorCode}
      </Title>
      <Space h={12} />
      <Text>{errorMessage || "An unexpected error occurred."}</Text>
      <Space h={24} />
    </Flex>
  )
}
