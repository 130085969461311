import {
  OrganizationFragment,
  usePresignedUrlLazyQuery,
  useUpdateOrganizationMutation,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { ImageDropzoneInput } from "@earnnest-e2-frontend/platform-ui/src/mantine/ImageDropzone"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import { DarkEarnnestTheme } from "@earnnest-e2-frontend/platform-ui/src/mantine/theme"
import {
  Button,
  ColorInput,
  Group,
  MantineProvider,
  Space,
  Stack,
  Text,
} from "@mantine/core"
import { useForm, yupResolver } from "@mantine/form"
import { notifications } from "@mantine/notifications"
import React, { ReactElement, useEffect, useState } from "react"
import * as yup from "yup"

export default function OrganizationBrandingForm({
  organization,
  submitLabel,
  onChange,
  SkipButton,
  onSubmitSuccess,
}: {
  organization: OrganizationFragment
  submitLabel?: string
  onChange?: (props: {
    backgroundColor: string
    logoLight: string
    logoDark: string
  }) => void
  SkipButton?: ReactElement
  onSubmitSuccess?: (organization: OrganizationFragment) => void
}) {
  const form = useForm({
    initialValues: {
      backgroundColor: organization.backgroundColor || "",
      logoLight: organization.logoLight || "",
      logoDark: organization.logoDark || "",
    },
    validate: yupResolver(
      yup.object({
        backgroundColor: yup.string(),
        logoLight: yup.mixed(),
        logoDark: yup.mixed(),
      }),
    ),
  })

  useEffect(() => {
    onChange?.({
      backgroundColor: form.values.backgroundColor,
      logoLight: form.values.logoLight
        ? typeof form.values.logoLight === "string"
          ? form.values.logoLight
          : URL.createObjectURL(form.values.logoLight)
        : "",
      logoDark: form.values.logoDark
        ? typeof form.values.logoDark === "string"
          ? form.values.logoDark
          : URL.createObjectURL(form.values.logoDark)
        : "",
    })
  }, [
    form.values.backgroundColor,
    form.values.logoDark,
    form.values.logoLight,
    form.values.slug,
    onChange,
  ])

  const [submitting, setSubmitting] = useState(false)

  const [getPresignedUrl] = usePresignedUrlLazyQuery()

  async function uploadImageFile(file: File) {
    const presignedUrlResult = await getPresignedUrl({
      fetchPolicy: "network-only",
    })
    const presignedUrl = presignedUrlResult.data.getPresignedUrl
    const imageUrl = `${process.env.REACT_APP_S3_ASSETS_DOMAIN}/uploads/${presignedUrl.split("?").shift().slice(presignedUrl.lastIndexOf("/") + 1)}` // prettier-ignore
    await fetch(presignedUrl, {
      method: "PUT",
      body: file,
    })
    return imageUrl
  }

  const [updateOrganizationMutation] = useUpdateOrganizationMutation()

  return (
    <form
      onSubmit={form.onSubmit(async (values) => {
        try {
          setSubmitting(true)

          let logoLightUrl = ""
          if (typeof values?.logoLight === "string") {
            logoLightUrl = values.logoLight
          } else {
            logoLightUrl = await uploadImageFile(values.logoLight)
          }

          let logoDarkUrl = ""
          if (typeof values?.logoDark === "string") {
            logoDarkUrl = values.logoDark
          } else {
            logoDarkUrl = await uploadImageFile(values.logoDark)
          }

          const updateOrganizationResult = await updateOrganizationMutation({
            variables: {
              orgId: organization.id,
              slug: values.slug,
              backgroundColor: values.backgroundColor || undefined,
              logoLight: logoLightUrl || undefined,
              logoDark: logoDarkUrl || undefined,
            },
          })

          onSubmitSuccess?.(updateOrganizationResult.data.updateOrganization)
        } catch (error) {
          console.error(error)
          notifications.show({
            color: "red",
            title: "Error",
            message: "Something went wrong",
          })
        } finally {
          setSubmitting(false)
        }
      })}>
      <Text size="sm">
        Manage your payment portal URL and customize your branding elements
        below. Branding is optional, but recommended to ensure payers easily
        recognize your organization.
      </Text>
      <Space h="lg" />
      <Stack spacing="sm">
        <Group grow align="start">
          <ImageDropzoneInput
            label="Logo for light backgrounds (optional)"
            w="100%"
            h="10rem"
            {...form.getInputProps("logoLight")}
          />
          <MantineProvider theme={DarkEarnnestTheme}>
            <ImageDropzoneInput
              label="Logo for dark backgrounds (optional)"
              w="100%"
              h="10rem"
              {...form.getInputProps("logoDark")}
            />
          </MantineProvider>
        </Group>
        <ColorInput
          label="Brand Color (optional)"
          placeholder="#30C97F"
          {...form.getInputProps("backgroundColor")}
          sx={(theme) => ({
            width: `calc(50% - ${theme.spacing.xs})`,
            [theme.fn.smallerThan("xs")]: {
              width: "100%",
            },
          })}
        />
      </Stack>
      <PanelFooter>
        {SkipButton ? (
          <>
            {React.cloneElement(SkipButton)}
            <Space h="xs" />
          </>
        ) : null}
        <Button type="submit" size="lg" loading={submitting}>
          {submitLabel || "Save Changes"}
        </Button>
      </PanelFooter>
    </form>
  )
}
