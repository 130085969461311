import { useState } from "react"
import useScript from "react-script-hook"
import { usePersistedState } from "../hooks"
import { useCreatePlaidLinkTokenMutation } from "../graphql"
import { useEarnnestAnalytics } from "./EarnnestAnalytics"

export type OpenPlaidLinkOptions = {
  // Callbacks from Plaid Link after user succeeds or fails to select a bank account
  onSuccess: (token: string, metadata: any) => void
  onError?: (Error) => void
  // Use to reinitialize a Plaid Link when user returns from a Plaid oauth redirect
  manualToken?: string
  receivedRedirectUri?: string
  // Use to reinitialize a Plaid Link in "update" mode for existing funding source
  fundingSourceId?: string
}

export function useEarnnestPlaidLink(): [
  (options: OpenPlaidLinkOptions) => void,
  { loading: boolean; error: Error | null },
] {
  const { track } = useEarnnestAnalytics()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const [createPlaidLinkToken] = useCreatePlaidLinkTokenMutation()
  const [_, setLinkToken] = usePersistedState("plaidLinkToken", null) // eslint-disable-line

  async function openPlaidLink({
    receivedRedirectUri,
    manualToken,
    fundingSourceId = null,
    onSuccess,
    onError,
  }: OpenPlaidLinkOptions) {
    if (loading) {
      return
    }

    async function handleError(msg: string) {
      track("Plaid Link Errored", { error: msg })
      setError(new Error(msg))
      onError?.(new Error(msg))
    }

    try {
      setLoading(true)

      let plaidLinkToken
      if (!manualToken) {
        const result = await createPlaidLinkToken({
          variables: {
            fundingSourceId,
            redirectUri: `${window.location.origin}/plaid-continue`,
          },
        })
        plaidLinkToken = result.data.createPlaidLinkToken.linkToken
        setLinkToken(plaidLinkToken)
      } else {
        plaidLinkToken = manualToken
      }

      const plaidLink = (window as any).Plaid.create({
        env: process.env.REACT_APP_PLAID_ENV,
        token: plaidLinkToken,
        receivedRedirectUri: receivedRedirectUri || null,
        onSuccess: (token, metadata) => {
          track("Plaid Link Succeeded")
          // prettier-ignore
          if (
            // Don't support depository account types
            metadata?.account?.type !== "depository" ||
            // Only support checking & savings account subtypes
            (metadata?.account?.subtype !== "checking" && metadata?.account?.subtype !== "savings") ||
            // Don't support savings accounts from Navy Federal Credit Union (plaid id = ins_15)
            (metadata?.institution?.institutionId === "ins_15" && metadata?.account?.subtype === "savings")
          ) {
            return handleError(
              "This account type is not supported. Please select another one.",
            )
          } else {
            onSuccess(token, metadata)
          }
        },
        onExit: (error) => {
          if (error) {
            handleError(error.error_message)
          }
        },
      })
      track("Plaid Link Opened")
      plaidLink.open()
    } catch (error) {
      console.error(error)
      let msg = error?.message || "An unexpected error occured."
      handleError(msg)
    } finally {
      setLoading(false)
    }
  }

  return [openPlaidLink, { error, loading }]
}

export function EarnnestPlaidLinkProvider({ children }: { children: any }) {
  // Asynchronously load the Plaid Link stable url into the DOM
  useScript({
    src: "https://cdn.plaid.com/link/v2/stable/link-initialize.js",
    checkForExisting: true,
  })

  return children
}
