import {
  useRequestUserRolesMutation,
  UserPermission,
  useUserQuery,
} from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import { Box, Button, Flex, Space, Stack, Text, Title } from "@mantine/core"
import { notifications } from "@mantine/notifications"
import { useState } from "react"
import { RiCheckboxCircleFill } from "react-icons/ri"

export default function IndividualFrontMatterScreen() {
  const userQuery = useUserQuery({ fetchPolicy: "no-cache" })
  const user = userQuery.data?.user
  const [requestUserRoles] = useRequestUserRolesMutation({
    variables: {
      roles: [UserPermission.RequestEscrow],
    },
  })
  const [submitting, setSubmitting] = useState(false)

  function redirectToAgentDomain() {
    window.location.href = `${process.env.REACT_APP_AGENT_DOMAIN}`
  }

  if (user?.roles?.includes(UserPermission.RequestEscrow)) {
    redirectToAgentDomain()
  }

  return (
    <form
      onSubmit={async (e) => {
        e.preventDefault()
        try {
          setSubmitting(true)
          await requestUserRoles()
          redirectToAgentDomain()
        } catch (error) {
          notifications.show({
            color: "red",
            title: "Error",
            message: "Something went wrong",
          })
        } finally {
          setSubmitting(false)
        }
      }}>
      <Space h={38} />
      <Title size="h2" order={2}>
        Register as an individual.
      </Title>
      <Space h={8} />
      <Text size="lg" color="gray.8">
        Earnnest makes it easy to request digital earnest money. Here's a quick
        overview of how easy it is:
      </Text>
      <Space h={40} />
      <Stack spacing={12}>
        <Flex direction="row" justify="start" align="start" gap={14}>
          <Box h={24} w={24} pt={1}>
            <RiCheckboxCircleFill size={24} />
          </Box>
          <Text size="lg">
            Request earnest money be sent to an enrolled escrow holder
          </Text>
        </Flex>
        <Flex direction="row" justify="start" align="start" gap={14}>
          <Box h={24} w={24} pt={1}>
            <RiCheckboxCircleFill size={24} />
          </Box>
          <Text size="lg">Buyer receives request and makes payment</Text>
        </Flex>
        <Flex direction="row" justify="start" align="start" gap={14}>
          <Box h={24} w={24} pt={1}>
            <RiCheckboxCircleFill size={24} />
          </Box>
          <Text size="lg">You are kept up to date as payment is processed</Text>
        </Flex>
      </Stack>
      <PanelFooter>
        <Button size="lg" type="submit" loading={submitting}>
          Begin
        </Button>
      </PanelFooter>
    </form>
  )
}
