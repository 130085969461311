import Wordmark from "../Earnnest/Wordmark"
import { Flex, MantineColor, Space, Text, useMantineTheme } from "@mantine/core"

export default function PoweredBy({ color }: { color: MantineColor }) {
  const theme = useMantineTheme()
  const themeColor = theme.fn.themeColor(color)
  return (
    <a
      href="https://earnnest.com/?utm_source=epro&utm_medium=click-thru&utm_campaign=powered-by-earnnest"
      rel="noopener noreferrer"
      target="_blank">
      <Flex direction="row" align="center" wrap="wrap">
        <Text color={themeColor} size="sm" my={4}>
          Powered by
        </Text>
        <Space w={8} />
        <Wordmark color={themeColor} style={{ height: 12 }} />
      </Flex>
    </a>
  )
}
