import { useOrganizationQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import OrganizationBrandingForm from "@earnnest-e2-frontend/platform-views/src/mantine/OrganizationBrandingForm"
import { Button, Box, Flex, Image, Space, Text, Title } from "@mantine/core"
import { useCallback } from "react"
import { useHistory, useParams } from "react-router-dom"
import tinycolor from "tinycolor2"

export default function BusinessBrandingScreen({ setBackdropProps }) {
  const history = useHistory()
  const { orgSlug } = useParams()

  const organizationQueryResult = useOrganizationQuery({
    variables: {
      slug: orgSlug,
    },
  })

  const organization = organizationQueryResult?.data?.organization

  const onBrandingValuesChange = useCallback(
    (values) => {
      if (organization) {
        const backdropColor = values.backgroundColor || null
        const logoToUse = tinycolor(backdropColor).isDark()
          ? values.logoDark
          : values.logoLight
        const backdrop = logoToUse ? (
          <Flex
            direction="column"
            align="center"
            sx={(theme) => ({
              borderBottomWidth: 2,
              borderColor: theme.fn.themeColor("dark.2"),
            })}>
            <Image src={logoToUse} width={300} />
            <Text mt={4}>
              {organization?.name || "This organization"} uses Earnnest for
              secure digital real estate payments.
            </Text>
          </Flex>
        ) : null
        setBackdropProps({ backdrop, backdropColor })
      }
    },
    [organization, setBackdropProps],
  )

  if (!organization) {
    return null
  }

  return (
    <Box>
      <Space h="xl" />
      <Title size="h2" order={2}>
        Set up your brand in Earnnest
      </Title>
      <Space h="xs" />
      <OrganizationBrandingForm
        organization={organization}
        submitLabel="Continue"
        onChange={onBrandingValuesChange}
        SkipButton={
          <Button
            variant="subtle"
            size="lg"
            onClick={() => {
              history.push(`/${organization?.slug}/escrow`)
            }}>
            Skip for now
          </Button>
        }
        onSubmitSuccess={(updatedOrg) => {
          history.replace({ pathname: `/${updatedOrg.slug}/escrow` })
        }}
      />
    </Box>
  )
}
