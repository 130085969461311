import { useEarnnestAnalytics } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAnalytics"
import { useEarnnestAuth0 } from "@earnnest-e2-frontend/platform-api/src/providers/EarnnestAuth0"
import LoadingOverlay from "@earnnest-e2-frontend/platform-ui/src/LoadingOverlay/LoadingOverlay"
import { useCallback, useEffect } from "react"
import { useLocation } from "react-router-dom"
import ErrorPage from "./ErrorPage"

export default function LoginPage() {
  const location = useLocation()
  const { error: authError, loginWithRedirect } = useEarnnestAuth0()
  const { track } = useEarnnestAnalytics()

  useEffect(() => {
    track("Login Viewed")
  }, [track])

  const handleLogin = useCallback(() => {
    track("Login Attempted")
    const returnTo = location.pathname + location.search
    localStorage.clear()
    localStorage.setItem("loginReturnTo", returnTo)
    loginWithRedirect({
      appState: { returnTo },
    })
  }, [loginWithRedirect, location, track])

  useEffect(() => {
    if (!authError) {
      handleLogin()
    }
  }, [authError, handleLogin])

  if (authError) {
    return (
      <ErrorPage errorCode={authError.name} errorMessage={authError?.message} />
    )
  }

  return <LoadingOverlay />
}
