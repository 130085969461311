import {
  BackgroundImage,
  Box,
  Center,
  Group,
  MantineProvider,
  Stack,
} from "@mantine/core"
import { ReactNode } from "react"
import { Panel } from "./Panel"
import { DarkEarnnestTheme } from "./theme"
// @ts-ignore
import bgPattern from "../assets/images/wing-pattern.svg"
import PoweredBy from "./PoweredBy"

interface PortalLayoutProps {
  panelOpened: boolean
  onPanelBack?: () => void
  onPanelClose?: () => void
  children: ReactNode
  backdrop?: ReactNode
  backdropColor?: string
}

export function PortalLayout({
  panelOpened,
  onPanelBack,
  onPanelClose,
  children,
  backdrop,
  backdropColor,
}: PortalLayoutProps) {
  return (
    <MantineProvider theme={DarkEarnnestTheme}>
      <Box bg="dark.7" h="100vh">
        <Panel
          position="left"
          overlay={false}
          opened={panelOpened}
          onBack={onPanelBack}
          onClose={onPanelClose}>
          {children}
        </Panel>
        <BackgroundImage
          src={backdropColor ? null : bgPattern}
          sx={(theme) => ({
            height: "100vh",
            position: "relative",
            transform: `translateX(${panelOpened ? "19.375rem" : "0rem"})`,
            transition: "transform 200ms",
            backgroundColor: backdropColor || theme.fn.themeColor("dark.7"),
            backgroundRepeat: "no-repeat",
            backgroundPosition: "70px 50%",
          })}>
          <Stack sx={{ minHeight: "100vh" }}>
            <Center sx={{ flex: 1 }}>{backdrop}</Center>
            <Group mt="auto" mb="xl" position="center">
              <PoweredBy color="dark.2" />
            </Group>
          </Stack>
        </BackgroundImage>
      </Box>
    </MantineProvider>
  )
}
