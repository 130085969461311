import {
  ActionIcon,
  BackgroundImage,
  Box,
  CSSObject,
  Input,
  Stack,
  Sx,
  Text,
  useMantineTheme,
} from "@mantine/core"
import { Dropzone, DropzoneProps } from "@mantine/dropzone"
import { notifications } from "@mantine/notifications"
import { ReactNode, useMemo } from "react"
import { RiCloseFill, RiFileForbidLine, RiImageAddLine } from "react-icons/ri"

export interface ImageDropzoneProps extends Omit<DropzoneProps, "children"> {
  // must provide width and height
  h: string | number
  w: string | number
  // File and uri string can be rendered as image preview
  value?: Blob | MediaSource | string | null
  // extend Dropzone styles prop to include styles for image preview
  styles?: DropzoneProps["styles"] & {
    root?: CSSObject
    inner?: CSSObject
    image?: Sx
  }
  // icons and text for dropzone
  uploadIcon?: ReactNode
  acceptIcon?: ReactNode
  rejectIcon?: ReactNode
  prompt?: ReactNode
}

export function ImageDropzone({
  value,
  styles,
  accept = ["image/png", "image/jpeg"],
  uploadIcon = <RiImageAddLine size={32} />,
  acceptIcon = <RiImageAddLine size={32} />,
  rejectIcon = <RiFileForbidLine size={32} />,
  prompt = (
    <Stack align="center" spacing={0}>
      <Text>Drop file or click to upload</Text>
      <Text size="sm" color="dimmed">
        Supports PNG or JPG
      </Text>
    </Stack>
  ),
  ...rest
}: ImageDropzoneProps) {
  const theme = useMantineTheme()

  const image = useMemo(() => {
    if (typeof value === "string") {
      return value
    } else if (value) {
      return URL.createObjectURL(value)
    }
  }, [value])

  return (
    <Dropzone
      {...rest}
      accept={accept}
      multiple={false}
      onReject={(rejections) => {
        notifications.show({
          color: "red",
          title: `${rejections.length > 1 ? "Files" : "File"} rejected`,
          autoClose: true,
          message: "Could not upload selected file(s).",
        })
      }}
      styles={{
        root: {
          borderRadius: 0,
          color:
            theme.colorScheme === "light"
              ? theme.fn.themeColor("dark.7")
              : theme.fn.themeColor("gray.0"),
          "&[data-accept]": {
            backgroundColor:
              (theme.colorScheme === "light"
                ? theme.fn.themeColor("green.1")
                : theme.fn.themeColor("green.9")) + "!important",
            borderColor: theme.fn.themeColor("green.4"),
          },
          ...styles?.root,
        },
        inner: {
          width: "100%",
          height: "100%",
          ...styles?.inner,
        },
      }}>
      {image ? (
        <BackgroundImage
          src={image}
          sx={{
            width: "100%",
            height: "100%",
            pointerEvents: "none",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            ...styles?.image,
          }}
        />
      ) : (
        <Stack
          align="center"
          justify="center"
          spacing="sm"
          sx={{
            width: "100%",
            height: "100%",
            visibility: image ? "hidden" : "visible",
          }}>
          <Dropzone.Accept>{acceptIcon}</Dropzone.Accept>
          <Dropzone.Reject>{rejectIcon}</Dropzone.Reject>
          <Dropzone.Idle>{uploadIcon}</Dropzone.Idle>
          {prompt}
        </Stack>
      )}
    </Dropzone>
  )
}

export interface ImageDropzoneInputProps
  extends Omit<ImageDropzoneProps, "onChange" | "onDrop"> {
  label: ReactNode
  description?: ReactNode
  error?: ReactNode
  onChange: (value: File | null) => void
}

export function ImageDropzoneInput({
  label,
  description,
  error,
  onChange,
  value,
  ...rest
}: ImageDropzoneInputProps) {
  return (
    <Input.Wrapper label={label} description={description} error={error}>
      <Box
        sx={{
          position: "relative",
          "&:not(:hover) > button": {
            display: "none",
          },
        }}>
        <ImageDropzone
          w={100}
          h={100}
          value={value}
          onDrop={(files) => onChange(files[0])}
          {...rest}
        />
        {value ? (
          <ActionIcon
            onClick={() => onChange(null)}
            variant="default"
            radius="xl"
            size="sm"
            sx={{
              position: "absolute",
              top: -8,
              right: -8,
            }}>
            <RiCloseFill />
          </ActionIcon>
        ) : null}
      </Box>
    </Input.Wrapper>
  )
}
