import { useOrganizationQuery } from "@earnnest-e2-frontend/platform-api/src/graphql"
import { PanelFooter } from "@earnnest-e2-frontend/platform-ui/src/mantine/Panel"
import { Button, Center, Space, Stack, Text, Title } from "@mantine/core"
import { RiCheckboxCircleFill } from "react-icons/ri"
import { useParams } from "react-router-dom"

export default function BusinessEscrowLandingScreen() {
  const { orgSlug } = useParams()

  const organizationQueryResult = useOrganizationQuery({
    variables: {
      slug: orgSlug,
    },
  })

  const organization = organizationQueryResult?.data?.organization

  const dashboardUrl = `${process.env.REACT_APP_DASHBOARD_DOMAIN}/${organization?.slug}`
  const createEscrowUrl = dashboardUrl + "/escrow-accounts/register"

  if (!organization) {
    return null
  }

  return (
    <Stack px={124}>
      <Space h="xl" />
      <Center sx={(theme) => ({ color: theme.fn.themeColor("green") })}>
        <RiCheckboxCircleFill size={128} />
      </Center>
      <Title align="center" size="h2" order={2}>
        Welcome, {organization.name}
      </Title>
      <Text align="center">
        Thanks for joining the future of how money moves in real estate. Next,
        let's register your escrow accounts so you can receive payments through
        Earnnest.
      </Text>
      <PanelFooter>
        <Button component="a" variant="subtle" size="lg" href={dashboardUrl}>
          Skip for now
        </Button>
        <Space h="xs" />
        <Button component="a" size="lg" href={createEscrowUrl}>
          Register your first account
        </Button>
      </PanelFooter>
    </Stack>
  )
}
